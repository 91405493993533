import crudMutations from "@/store/templates/b-crud/mutations";

export default {
  ...crudMutations,
  setName(state, name) {
    state.model.name = name;
  },
  setIsActive(state, isActive) {
    state.model.isActive = isActive;
  }
};
